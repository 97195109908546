@import "./variables.scss";

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .mainHeader {
        font-size: 3rem;

        .title{
            margin: 1rem;

            .byMaro {
                color: $rose;
                font-size: 2rem;
            }
        }

    }

    .subHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: $rose;

        .subHeaderLabel {
            padding: 20px;
            font-size: 1.25rem;

            @media (max-width: 425px) {
                padding: .75rem;
            }

            @media (max-width: 400px) {
                padding: .5rem;
            }

            @media (max-width: 369px) {
                padding: 3px;
            }

            @media (max-width: 320px) {
                padding: 1px;
            }

            a {
                text-decoration: none;
                color: $rose;
            }
        }
    }
}