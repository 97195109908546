@import "./variables.scss";

.services {
    display: flex;
    flex-direction: column;
    background-color: $maroon;
    color: $white;
    font-size: 1.5rem;

    .servicesContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    h3 {
        text-align: center;
        margin: 0.5rem 0;
    }

    ul {
        margin: 0.5rem 0;

        li {
            padding: .25rem;
        }
    }
}