@import "./styles/variables.scss";
@import "./styles/header.scss";
@import "./styles/contact.scss";
@import "./styles/footer.scss";
@import "./styles/imageGallery.scss";
@import "./styles/cardForImages.scss";
@import "./styles/services.scss";

body {
    margin: 0;
    padding: 0;
}

.App {
    // background-color: $lightOrange; // decide if we want this as the background color
    margin: 0;
    padding: 0;

    .home {
        font-family: $robotoCondensed;
        color: $maroon;
    }
}
